import { Box, Icon, makeStyles, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 1),
    backgroundColor: theme.palette.background.default,
  },

  title: {
    fontWeight: 800,
    fontSize: "1.2em",
    color: theme.palette.primary.dark,
    textTransform: "uppercase",
    letterSpacing: ".53px",
    marginLeft: theme.spacing(1),
  },

  status: {
    paddingTop: theme.spacing(1),
    fontWeight: 800,
    fontSize: ".8rem",
    color: theme.palette.primary.dark,
    textTransform: "uppercase",
    letterSpacing: ".53px",
  },

  date: {
    letterSpacing: ".4px",
    color: theme.palette.text.secondary,
    fontSize: ".8rem",
    lineHeight: "1em",
  },

  icon: {
    color: theme.palette.text.secondary,
  },
}));

export function DemandHeader({ name, status, createdAt, iconName }) {
  const classes = useStyles();
  const parsedDate = useMemo(
    () => (createdAt ? moment(createdAt).format("DD/MM/YYYY") : ""),
    [createdAt]
  );

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Icon fontSize="medium" className={classes.icon}>
          {iconName}
        </Icon>
        <Typography className={classes.title}>{name}</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Typography className={classes.status}>{status}</Typography>
        <Typography className={classes.date}>{parsedDate}</Typography>
      </Box>
    </Box>
  );
}
