import React from "react";
import { DemandDetails } from "../../components/DemandDetails";
import SideMenu from "../../components/menu";
import { historyApiDTOParser } from "../../ApiDTO/history";


function DemandDetailsPage() {
  return (
    <>
      <SideMenu />
      <DemandDetails historyParser={historyApiDTOParser}/>
    </>
  );
}

export default DemandDetailsPage;
