import { Box, makeStyles, SwipeableDrawer } from "@material-ui/core";
import React from "react";
import MenuHeader from "./MenuHeader";

const useStyles = makeStyles((them) => ({
  drawerContent: {
    width: "100%",
    flexDirection: "row-reverse",
    justifyContent: "space-around"
  },
}));

const isIOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

export function AsideDrawerMenu({
  anchor = "bottom",
  open,
  onClose,
  onOpen,
  children,
}) {
  const classes = useStyles();
  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      disableBackdropTransition={!isIOS}
      disableDiscovery={isIOS}
    >
      <Box
        display="flex"
        flexDirection="column"
        className={classes.drawerContent}
      >
        {children}
      </Box>
    </SwipeableDrawer>
  );
}
