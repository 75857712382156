import React from "react";
import {  Box, makeStyles } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  filterCardContainer: {
    display: "flex",
    alignItems: "center",
    overflowX: "scroll",
    width: "100%",
    padding: theme.spacing(1,0),
    "& > div":{
      marginLeft: theme.spacing(1)
    }
  },

  containerClosed: {},

  root: {
    backgroundColor: theme.palette.secondary.light,
    height: "40px",
    transition: `${theme.transitions.create(["height", "transform"], {
      duration: theme.transitions.duration.complex,
    })}`,
  },

  rootClosed: {
    height: 0,
    overflow: "hidden",
    transition: `${theme.transitions.create(["height", "transform"], {
      duration: theme.transitions.duration.complex,
    })}`,
  },

  filter: {
    color: "#fff",
    padding: theme.spacing(0, 1),
    boxShadow: '3px 0px 3px -2px rgb(0 0 0 / 20%), 0px 0px 4px 0px rgb(0 0 0 / 14%), 0px 0px 8px 0px rgb(0 0 0 / 12%)',
    background: "inherit",
    border: "none",
  },
}));

export function FilterResumedList({ children, open }) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      width="100%"
      className={clsx(classes.root, {
        [classes.rootClosed]: !open,
      })}
    >
      <Box
        display="flex"
        width="30%"
        className={classes.filter}
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        component="button"
      >
        <FilterListIcon size="medium" />
        <span>Filtros</span>
      </Box>
      <Box width="70%" className={classes.filterCardContainer}>
        {children}
      </Box>
    </Box>
  );
}
