import React, { useMemo } from "react";
import { useNotificationContext } from "../../context/NotificationContext";
import { InteractionDialog } from "../Dialogs/InteractionDialog";

// import { Container } from './styles';

export function NotificationContextConsumer() {
  const { currentDialog } = useNotificationContext();
  const hasDialogs = useMemo(() => !!currentDialog, [currentDialog]);

  if (!hasDialogs) return null;
  return <InteractionDialog open={hasDialogs} {...currentDialog} />;
}
