import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((them) => ({
  root: {
    height: "200px",
  },
}));

function MenuHeader() {
  const classes = useStyles();
  return (
    <Box display="flex" width="100%" className={classes.root}>
      <Typography>Prefeitura de Viana</Typography>
    </Box>
  );
}

export default MenuHeader;
