import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",
    width: "97%",
    color: theme.palette.primary.dark,
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    paddingBottom: '4px',
    marginTop: theme.spacing(2),
    "& > span": {
      marginLeft: '3px',
      fontSize: '.9rem',
      fontWeight: 800,
    }
  },
}));

function ListItemHeading({ children }) {
  const classes = useStyles();
  return (
    <Box className={classes.heading}>
      <span>{children}</span>
    </Box>
  );
}

export default ListItemHeading;
