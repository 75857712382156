import React, { Fragment, useRef } from "react";
import ListItemHeading from "./ListItemHeading";
import RequestCard from "./RequestCard";
import { RequestCardSkeleton } from "./RequestCardSkeleton";

// import { Container } from './styles';

export function DemandInfiniteList({
  demands,
  comparator: isEqualDemand,
  getText,
}) {
  const requestLastMemo = useRef(false);
  return demands.map((request, index) => {
    if (request.isSkeleton) return <RequestCardSkeleton />;
    
    const hasPreviousValue = !!requestLastMemo.current;
    const shouldRenderHeader =
      index === 0 ||
      (hasPreviousValue
        ? !isEqualDemand(request, requestLastMemo.current)
        : true);
    requestLastMemo.current = request;

    if (shouldRenderHeader) {
      const text = getText(request);
      return (
        <Fragment key={`request-${index}`}>
          <ListItemHeading>{text}</ListItemHeading>
          <RequestCard {...request} />
        </Fragment>
      );
    }
    return <RequestCard {...request} key={`request-${index}`} />;
  });
}
