import { useCallback, useEffect, useState } from "react";

export const useUserPosition = (requestGeolocationOptions) => {
    const [hasProblems, setHasProblems] = useState(false);
    const [readyState, setReadyState] = useState(false);
    const [latLong, setLagLong] = useState([-20.3752005,-40.4722058]); //Viana
  
    const onLocationReceived = useCallback(({ coords }) => {
      setReadyState(true);
      setHasProblems(false);
      setLagLong([coords.latitude, coords.longitude]);
    }, []);
  
    const onLocationError = useCallback(() => {
      setHasProblems(true);
      setReadyState(false);
    }, []);
  
    useEffect(() => {
      const hasGeolocationApi = !!navigator.geolocation;
      if (!hasGeolocationApi) return setHasProblems(true);
  
      const watcherId = navigator.geolocation.watchPosition(
        onLocationReceived,
        onLocationError,
        requestGeolocationOptions
      );
  
      return () => navigator.geolocation.clearWatch(watcherId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onLocationReceived, onLocationError]);
  
    return {
      hasProblems,
      readyState,
      latLong,
    };
  };