import React from "react";
import SideMenu from "../../components/menu";
import RequestList from "../../components/RequestList";

export function Demands() {
  return (
    <>
      <SideMenu />
      <RequestList />;
    </>
  );
}
