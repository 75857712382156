import React from "react";
import { Box, Grid, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootWrapper: {
    width: "100%",
    padding: theme.spacing(1),
  },

  list: {
    listStyleType: "none",
    margin: "0",
    padding: 0,
    flexBasis: "row-reverse",
  },

  textField: {
    "& .MuiOutlinedInput-adornedStart": {
      padding: theme.spacing(0, 2),
    },

    "& input": {
      display: "none",
    },
  },
}));

export function Pictures({ children, shouldRenderFallback }) {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      className={classes.textField}
      variant="outlined"
      label="Fotos"
      InputProps={{
        startAdornment: (
          <Box position="relative" className={classes.rootWrapper}>
            <Grid
              component="ul"
              spacing={1}
              container
              justifyContent="flex-start"
              className={classes.list}
            >
              {children}
              {shouldRenderFallback && (
                <Grid item xs={12}>
                  <p>Não há fotos</p>
                </Grid>
              )}
            </Grid>
          </Box>
        ),
      }}
    />
  );
}
