import {
  Backdrop,
  Box,
  CircularProgress,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { DemandApi } from "../../services/api/demands";
import AppBarComponent from "../appbar";
import { DemandHeader } from "./DemandHeader";
import DemandForm from "./DemandForm";
import { HalfwayDialog } from "../DinamicHeightDialog";
import { useParams } from "react-router-dom";
import { useNotificationContext } from "../../context/NotificationContext";
import { enNotificationTypes } from "../../enums/notification-types";
import { enHistoryTypes } from "../../enums/service";
import { History } from "../History";
import HistoryForm from "./AppendDemandInfoForm/index";
import SwipeableViews from "react-swipeable-views";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundClip: theme.palette.background.paper,
  },
  headerWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    zIndex: theme.zIndex.appBar,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  headerOffset: {
    marginTop: "100px",
  },
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const enDemandTabs = {
  CONTENT: 0,
  HISTORY: 1,
};

export function DemandDetails({ historyParser }) {
  const [isLoading, setIsLoading] = useState(true);
  const [demand, setDemand] = useState({});
  const classes = useStyles();
  const { demandId } = useParams();
  const [demandTab, setDemandTab] = useState(enDemandTabs.CONTENT);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historyCreationStatus, setHistoryCreationStatus] = useState(
    enHistoryTypes.CLOSING
  );

  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const { addDialog } = useNotificationContext();

  const onInsertData = useCallback(() => {
    setHistoryCreationStatus(enHistoryTypes.MESSAGE);
    openModal();
  }, [openModal]);

  const onFinish = useCallback(() => {
    setHistoryCreationStatus(enHistoryTypes.CLOSING);
    openModal();
  }, [openModal]);

  const getDemandAndStore = useCallback(async (demandId) => {
    setIsLoading(true);
    try {
      const demand = await DemandApi.findOne(demandId);
      if (!demand) throw new Error("bad-request");
      setDemand(demand);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const submit = useCallback(
    async (history) => {
      try {
        closeModal();
        setIsLoading(true);
        const historyFormData = historyParser(history);
        await DemandApi.addHistory(demand._id, historyFormData);
        await getDemandAndStore(demand._id);
      } catch (error) {
        const shouldRetry = await addDialog({
          title: "Problemas de comunicação",
          message: `${error.message}\nHouve um problema ao envias os dados, gostaria de tentar novamente?`,
          type: enNotificationTypes.INFO,
          showCancelButton: true,
        });

        if (shouldRetry) submit(history);
      } finally {
        setIsLoading(false);
      }
    },
    [demand, closeModal, addDialog, historyParser, getDemandAndStore]
  );

  useEffect(() => {
    getDemandAndStore(demandId);
  }, [demandId, getDemandAndStore]);

  const handleChangeTab = useCallback((event, index) => {
    setDemandTab(index);
  }, []);

  return (
    <Box width="100%" height="100%" className={classes.root}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.headerWrapper}>
        <AppBarComponent title="Ver demanda" position="fixed">
          <Box bgcolor="background.paper">
            <Tabs
              value={demandTab}
              variant="fullWidth"
              onChange={handleChangeTab}
              indicatorColor="secondary"
              textColor="primary"
            >
              <Tab label="Demanda" {...a11yProps(enDemandTabs.CONTENT)} />
              <Tab label="Histórico" {...a11yProps(enDemandTabs.HISTORY)} />
            </Tabs>
          </Box>
        </AppBarComponent>
      </Box>
      <div className={classes.headerOffset} />

      <SwipeableViews index={demandTab} onChangeIndex={demandTab} enableMouseEvents={false}>
        <TabPanel height="100vh">
          <DemandHeader
            name={demand.service?.name || ""}
            status={demand.status?.private || ""}
            createdAt={demand.deadline  || ""}
            iconName={demand.service?.icon}
          />
          <DemandForm
            demand={demand}
            formState="onlyRead"
            onInsertData={onInsertData}
            onFinish={onFinish}
          />
        </TabPanel>
        <TabPanel height="100vh">
          <DemandHeader
            name={demand.service?.name || ""}
            status={demand.status?.private || ""}
            createdAt={demand.updatedAt || ""}
            iconName={demand.service?.icon}
          />
          <History demand={demand} highlightCount={2} />
        </TabPanel>
      </SwipeableViews>

      <HalfwayDialog open={isModalOpen} handleClose={closeModal} height="90vh">
        <HistoryForm
          onSubmit={submit}
          onCancel={closeModal}
          status={historyCreationStatus}
        />
      </HalfwayDialog>
    </Box>
  );
}
