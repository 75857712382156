import React, { useCallback, useRef } from "react";
import {
  Box,
  Button,
  makeStyles,
  TextField,
  useTheme,
} from "@material-ui/core";
import { Pictures, CardImage } from "../../PictureShow";

import { AddPicturesButton } from "./AddPicturesButton";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    color: theme.palette.primary.main,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    border: "1px",
    "border-radius": "35px",
    width: "253px",
    height: "58px",
  },

  buttonContainer: {
    color: theme.palette.primary.main,
    "& > button": {
      margin: theme.spacing(1, 0),
    },
  },

  textField: {
    maxHeight: "150px",
    zIndex: theme.zIndex.modal + 2,
  },
}));

export function DemandInfoForm({
  images,
  onSubmit,
  onCancel,
  handleSelectImage,
  onImageUpload,
  onImageRemove,
  historyStatus,
}) {
  const classes = useStyles();
  const inputDescriptionRef = useRef();
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      description: "",
    },
  });

  const submit = useCallback(
    ({ description }) => {
      onSubmit({ images, description, status: historyStatus });
    },
    [images, onSubmit, historyStatus]
  );

  return (
    <Box
      onSubmit={handleSubmit(submit)}
      component="form"
      p="15px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <TextField
        ref={inputDescriptionRef}
        label="Observação"
        placeholder="Observação"
        color="primary"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          className: classes.textField,
        }}
        variant="outlined"
        multiline
        {...register("description", { required: true })}
        error={!!formState.errors?.description?.type}
      />
      <Pictures>
        {images
          .map((image, index) => {
            const key = `${image.file.name}-${image.file.lastModified}}-${index}`;
            return (
              <CardImage
                isDeletable={true}
                id={key}
                key={key}
                src={image.dataURL}
                fileName={image.file.name}
                onClick={() => handleSelectImage(image.dataURL)}
                onDelete={() => onImageRemove(index)}
              />
            );
          })
          .reverse()}
        {3 - images.length !== 0 && (
          <AddPicturesButton
            onImageUpload={onImageUpload}
            number={3 - images.length}
            disabled={3 - images.length === 0}
          />
        )}
      </Pictures>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.buttonContainer}
        marginTop="auto"
      >
        <Button className={classes.confirmButton} type="submit">
          Confirmar
        </Button>
        <Button color="inherit" onClick={onCancel}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
}
