import { Fcm } from "../push-notifications/fcm/init-fcm";
import firebaseConfig from "../push-notifications/fcm/fcm-config.json";
import vapidKey from "../push-notifications/fcm/vapidKey-fcm.json";
import { FcmManager } from "../push-notifications/fcm/manager";
import AuthenticateService from "../services/api/authenticate";

const fcm = new Fcm({ ...vapidKey, ...firebaseConfig });
const fcmManager = new FcmManager(fcm, { authService: AuthenticateService });

export function AppDependenciesFactory() {
  return { fcmManager };
}
