import React from "react";
import { Box, Grid, IconButton, makeStyles } from "@material-ui/core";
import If from "../If";
import Delete from "@material-ui/icons/DeleteOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  image: {
    top: "50%",
    width: "100%",
    position: "relative",
    transform: "translateY(-50%)",
  },

  deleteItem: {
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    height: "40px",
    position: "absolute",
    background: "rgba(0, 0, 0, 0.4)",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    padding: theme.spacing(0, 1),
    color: theme.palette.secondary.light,
  },

  itemWrapper: {
    backgroundColor: theme.palette.background.default,
    height: "30vw",
  },

  fileName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: ".65rem",
    color: theme.palette.background.paper,
  },

  deleteButton: {
    color: theme.palette.error.light,
    "&.MuiIconButton-root": {
      padding: 0,
    },
  },
}));

export const CardImage = ({
  src,
  onClick,
  onDelete,
  isDeletable,
  fileName = "Deletar",
}) => {
  const classes = useStyles();

  return (
    <Grid component="li" item xs={4} className={classes.itemWrapper}>
      <Box display="block" height="100%" overflow="hidden" position="relative">
        <img src={src} alt="t" className={classes.image} onClick={onClick} />
        <If condition={isDeletable}>
          <Box className={classes.deleteItem}>
            <span className={classes.fileName}>{fileName}</span>
            <IconButton
              color="inherit"
              className={classes.deleteButton}
              onClick={onDelete}
            >
              <Delete />
            </IconButton>
          </Box>
        </If>
      </Box>
    </Grid>
  );
};
