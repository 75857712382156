import React from "react";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  addPicButton: {
    width: "100%",
    height: "100%",
  },

  addButtonWrapper: {
    height: "calc(30vw - 8px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.text.secondary,
    color: theme.palette.getContrastText(theme.palette.text.secondary),
  },
}));

export const AddPicturesButton = ({
  onImageUpload,
  number,
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <Grid component="li" item xs={4} className={classes.addButton}>
      <Box
        position="relative"
        display="block"
        className={classes.addButtonWrapper}
      >
        <IconButton
          color="inherit"
          className={classes.addPicButton}
          onClick={onImageUpload}
          disabled={disabled}
        >
          <Add fontSize="large" color="inherit" />
          <Typography variant="h3" component="span" color="inherit">
            {number}
          </Typography>
        </IconButton>
      </Box>
    </Grid>
  );
};
