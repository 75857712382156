export const enUserRoles = {
    ADMIN: 'ADMIN',
    MAYOR: 'MAYOR',
    SECRETARY: 'SECRETARY',
    SECTOR_MANAGER: 'SECTOR_MANAGER',
    SECTOR_MEMBER: 'SECTOR_MEMBER',
    SERVICE_PROVIDER: 'SERVICE_PROVIDER',
    PUBLIC_PERSON: 'PUBLIC_PERSON',
    USER: 'USER',
    SERVICES_REQUESTER: 'SERVICE_REQUESTER'
};