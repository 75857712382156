import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { enAuthEvents } from "../enums/auth-events";
import { enErrors } from "../enums/error-events";
import { enNotificationTypes } from "../enums/notification-types";
import { useFcm } from "../push-notifications/fcm/fcm-context";
import { AppEventsManager } from "../services/emitter";

const throwError = () => {
  throw new Error("Notification Context isn't read");
};

/**
 * Dialog object description
 * @typedef {Object} Dialog
 * @property { string } message - Messsage of dialog
 * @property {"error" | "warning" | "success" | "info" } type - Define dialog color to be used
 * @property { string } title - Dialog title
 * @property { boolean } [showCancelButton] - Whether show or not cancel button
 * @property { Function } [onClickOk] - callback called when click ok button
 * @property { Function } [onClickCancel] - callback called when click ok button
 * @property { string } [okText] Button text on the right
 * @property { string } [cancelText] Button text on the left
 */

function handleDefaultDialogProperty(
  dialog,
  defaultOnClickOk,
  defaultOnClickCancel
) {
  const requiredProps = ["type", "title"];
  const hasRequiredProps = requiredProps.every((prop) =>
    dialog.hasOwnProperty(prop)
  );
  if (!hasRequiredProps) throw new Error("Missing dialogRequired prop");

  return {
    type: dialog.type,
    title: dialog.title,
    message: dialog.message,
    onClickOk: defaultOnClickOk,
    okText: dialog.okText || "Ok",
    onClickCancel: defaultOnClickCancel,
    showCancelButton: !!dialog.cancelText || !!dialog.showCancelButton,
    cancelText: dialog.cancelText || "Cancelar",
  };
}

export const NotificationContext = createContext({
  dialogs: [],
  currentDialog: null,
  addDialog: throwError,
  popDialog: throwError,
});

export const NotificationContextProvider = ({ children }) => {
  const [dialogs, setDialogs] = useState([]);
  const [currentDialog, setCurrentDialog] = useState(null);

  const popDialog = useCallback(() => {
    setDialogs((old) => {
      const clone = [...old];
      clone.pop();
      return clone;
    });
  }, []);

  const addDialog = useCallback(
    async (_dialog) => {
      const userResponsePromise = new Promise((resolve) => {
        const dialog = handleDefaultDialogProperty(
          _dialog,
          () => resolve(true),
          () => resolve(false)
        );
        setDialogs((old) => [dialog].concat(old));
      });

      const userResponse = await userResponsePromise;
      popDialog();

      return userResponse;
    },
    [popDialog]
  );

  const onAppError = useCallback(
    (error) =>
      addDialog({
        message: error.message,
        type: enNotificationTypes.ERROR,
        title: error.name || "Erro não esperado",
        showCancelButton: false,
      }),
    [addDialog]
  );

  const onAppForceLogout = useCallback(
    (error) =>
      addDialog({
        message: error.message || "Logout token expirado",
        type: error.type || enNotificationTypes.ERROR,
        title: error.title || "Notificação do sistema",
        showCancelButton: false,
      }),
    [addDialog]
  );

  useEffect(() => {
    setCurrentDialog(null);
    if (dialogs.length)
      setTimeout(() => setCurrentDialog(dialogs.slice(-1)[0]), 150);
  }, [dialogs]);

  const onFcmMessage = useCallback(
    (payload) => {
      addDialog({
        message: payload.body || "Logout token expirado",
        type: enNotificationTypes.INFO,
        title: payload.title || "Notificação do sistema",
        showCancelButton: false,
      });
    },
    [addDialog]
  );

  const { onMessage } = useFcm();

  useEffect(() => {
    const unsubscribe = onMessage(onFcmMessage);
    return unsubscribe;
  }, [onMessage, onFcmMessage]);

  useEffect(() => {
    AppEventsManager.on(enAuthEvents.FORCE_LOGOUT, onAppForceLogout);
    AppEventsManager.on(enErrors.GLOBAL, onAppError);

    return () => {
      AppEventsManager.off(enAuthEvents.FORCE_LOGOUT, onAppForceLogout);
      AppEventsManager.off(enErrors.GLOBAL, onAppError);
    };
  }, [onAppError, onAppForceLogout]);

  return (
    <NotificationContext.Provider
      value={{
        dialogs,
        addDialog,
        popDialog,
        currentDialog,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotificationContext() {
  const context = useContext(NotificationContext);
  return context;
}
