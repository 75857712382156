import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: "95%",
    margin: theme.spacing(1),
    minHeight: "10vh",
    display: 'flex',
  },
}));

export const RequestCardSkeleton = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box width="20%">
        <Skeleton animation="wave" variant="rect" height="50px" width="50px" />
      </Box>
      <Box width="80%">
        <Skeleton animation="wave" width="60%" ><Typography variant="h5">.</Typography></Skeleton>
        <Skeleton  animation="wave"/>
      </Box>
    </Box>
  );
};
