export const enOrder = {
  ASC: -1,
  DESC: 1,
};

export const enOrderType = {
  DATA: 0,
  BAIRRO: 1,
  STATUS: 2,
};

export const enOrderTypeApiMapper = {
  0: "createdAt",
  1: "createdAt",
  2: "status.private",
}
