import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { Heading } from "../../components/Heading";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "hidden",
    background: "#0b366f",
    padding: theme.spacing(2),
    color: "#fff",
    "& > div": {
      marginBottom: theme.spacing(3),
    },
  },
  sendButton: {
    color: "white",
    background: "#0059d6",
    "border-radius": "35px",
    width: "253px",
    height: "47px",
    textTransform: "uppercase",
  },
}));

export function PasswordRecoverySuccess() {
  const classes = useStyles();
  const history = useHistory();
  const email = useMemo(() => history.location.state?.email, [history]);
  if (!email) {
    return <Redirect to="/login" />;
  }
  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Box>
        <Heading>Esqueceu sua senha?</Heading>
      </Box>
      <Box>
        <Typography variant="subtitle1" color="inherit">
          E-mail de redefinição de senha enviado com sucesso!
        </Typography>
      </Box>
      <Box flexGrow={6}>
        <Typography variant="body2" color="inherit">
          O e-mail foi enviado para o endereço <b>{email}</b>. Verifique sua
          caixa de menssagens e siga as instruções para redefinir sua senha e
          então clique no botão abaixo para realizar o login
        </Typography>
      </Box>
      <Box
        marginBottom="8"
        display="flex"
        alignContent="center"
        justifyContent="center"
        flexGrow={1}
      >
        <Button
          component={Link}
          to="/public/login"
          className={classes.sendButton}
        >
          Voltar para o login
        </Button>
      </Box>
    </Box>
  );
}
