import { enHistoryPropNames } from "../enums/history-props";

export function historyApiDTOParser({ images, description, status }) {
  const historyDTO = new FormData();
  historyDTO.append(enHistoryPropNames.MESSAGE, description);
  images.forEach(({ file }) => historyDTO.append(enHistoryPropNames.ATTACHMENTS, file));
  historyDTO.append(enHistoryPropNames.HISTORY_TYPE, status);
  historyDTO.append(enHistoryPropNames.IS_PUBLIC, false);

  return historyDTO;
}
