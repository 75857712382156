import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFilterContext } from "./FilterContext";
import { filterSchemaValidator } from "./filterSchemaValidator";
import { yupResolver } from "@hookform/resolvers/yup";
import RemoveIcon from "@material-ui/icons/Remove";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { enHistoryTypeLabel } from "../../enums/service";
const useStyles = makeStyles((theme) => ({
  flexBoxFullWidth: {
    padding: theme.spacing(0, 1),
    display: "flex",
    width: "100%",
  },

  formContainer: {
    "& > div": {
      margin: theme.spacing(2, 0),
    },
    padding: theme.spacing(2),
  },

  dateIntervalWrapper: {
    alignContent: "center",
    justifyContent: "space-between",
  },

  dateIntervalInput: {
    fontSize: ".85rem",
    minWidth: "33vw"
  },

  confirmButton: {
    color: theme.palette.primary.main,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    border: "1px",
    "border-radius": "35px",
    width: "253px",
    height: "58px",
  },
  checkbox: {
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    color: theme.palette.text.secondary,
  },

  sessionLabel: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.text.primary,
    fontSize: ".93rem",
  },
}));

const defaultDateFormat = "YYYY-MM-DD";

const DEFAULT_STATE = {
  intervalStart: "",
  intervalEnd: "",
  services: [],
  status: [],
  requesterName: "",
  address: "",
};

export function FilterForm({ filters, onChange, onCancel }) {
  const defaultValues = useMemo(() => {
    if (filters.intervalStart)
      filters.intervalStart = moment(filters.intervalStart).format(
        defaultDateFormat
      );
    if (filters.intervalEnd)
      filters.intervalEnd = moment(filters.intervalEnd).format(
        defaultDateFormat
      );
    return {
      ...DEFAULT_STATE,
      ...filters,
    };
  }, [filters]);

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues,
    resolver: yupResolver(filterSchemaValidator),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const { availableServices, availableStatus } = useFilterContext();

  const classes = useStyles();
  return (
    <Box
      className={`${classes.flexBoxFullWidth} ${classes.formContainer}`}
      flexDirection="column"
      component="form"
      onSubmit={handleSubmit(onChange)}
      alignItems="center"
    >
      <Box width="100%">
        <Typography className={classes.sessionLabel}>Data</Typography>
        <Box
          className={`${classes.flexBoxFullWidth} ${classes.dateIntervalWrapper}`}
        >
          <TextField
            placeholder="Inicio"
            type="date"
            {...register("intervalStart")}
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={new Date()}
            className={classes.dateIntervalInput}
          />
          <RemoveIcon style={{ margin: "0 8px" }} />
          <TextField
            placeholder="Fim"
            type="date"
            {...register("intervalEnd")}
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={new Date()}
            className={classes.dateIntervalInput}
          />
        </Box>
      </Box>
      <Box width="100%">
        <Typography className={classes.sessionLabel}>Serviço</Typography>
        <Box className={classes.flexBoxFullWidth}>
          <Controller
            control={control}
            name="services"
            render={({ field: { value, onChange } }) => {
              return (
                <Autocomplete
                  multiple
                  fullWidth
                  value={value}
                  id="service-select"
                  options={availableServices}
                  getOptionLabel={(service) => service.name}
                  getOptionSelected={(a,b) => a._id === b._id}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="standard"
                      placeholder="Selecione um ou mais serviços"
                    />
                  )}
                />
              );
            }}
          />
        </Box>
      </Box>
      <Box width="100%">
        <Typography className={classes.sessionLabel}>Status</Typography>

        <Box className={classes.flexBoxFullWidth}>
          <Controller
            control={control}
            name="status"
            render={({ field: { value, onChange } }) => {
              return (
                <Autocomplete
                  multiple
                  fullWidth
                  id="status-select"
                  options={availableStatus}
                  getOptionLabel={(service) => service}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        variant="standard"
                        placeholder="Selecione um ou mais status"
                      />
                    );
                  }}
                />
              );
            }}
          />
        </Box>
      </Box>

      <Box width="100%">
        <Typography className={classes.sessionLabel}>
          Nome do solicitante
        </Typography>
        <Box className={classes.flexBoxFullWidth}>
          <TextField
            fullWidth
            {...register("requesterName")}
            placeholder="Insira o nome de um solicitante"
          />
        </Box>
      </Box>

      <Box width="100%">
        <Typography className={classes.sessionLabel}>Endereço</Typography>

        <Box className={classes.flexBoxFullWidth}>
          <TextField
            fullWidth
            {...register("address")}
            placeholder="Insira um logradouro"
          />
        </Box>
      </Box>

      <Box width="100%">
        <Typography className={classes.sessionLabel}>
          Filtros especiais
        </Typography>

        <Box className={classes.flexBoxFullWidth} flexDirection="column">
          <Controller
            control={control}
            name="publicPerson"
            render={({ field: { value, ...rest } }) => (
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox color="secondary" checked={!!value} {...rest} />
                }
                label="Pessoa pública?"
                labelPlacement="start"
              />
            )}
          />
          <Controller
            control={control}
            name="fisicPerson"
            render={({ field: { value, ...rest } }) => (
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox color="secondary" checked={!!value} {...rest} />
                }
                label="Pessoa Fisica"
                labelPlacement="start"
              />
            )}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.buttonContainer}
      >
        <Button className={classes.confirmButton} type="submit">
          Ver resultados
        </Button>
        <Button color="inherit" onClick={onCancel}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
}
