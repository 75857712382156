import {
  alpha,
  Box,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";
import React from "react";
import { green, red } from "@material-ui/core/colors";
import { enHistoryTypeLabel } from "../../enums/service";
import renderHTML from "react-render-html";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  textDescription: {
    textIndent: "1em",
    fontSize: ".7rem",
    letterSpacing: ".07rem",
    lineHeight: "1rem",
    color: alpha(theme.palette.text.secondary, 0.7),
  },
  authorText: {
    fontSize: ".7rem",
    lineHeight: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.text.secondary,
    textIdent: "1em",
    textAlign: "justify",
  },
  iconButton: {
    padding: theme.spacing(0, 1),
  },
  arrow: {
    fontSize: "2.3rem",
    top: "2px",
    left: "-33px",
    position: "absolute",
    color: ({ isPublic }) => (isPublic ? green[200] : red[200]),
  },
  link: {
    textDecoration: "underline",
    fontSize: ".7rem",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    letterSpacing: ".09em",
  },
}));

export function ResumedItemDescription({
  isPublic,
  message,
  onClickInfo,
  requester,
  type,
  footerInfo,
}) {
  const classes = useStyles({
    isPublic: !!isPublic,
  });
  return (
    <Box className={classes.root} paddingTop="8px">
      <ArrowRight className={classes.arrow} />
      <Box display="flex" justifyContent="space-between" paddingBottom="8px">
        <Typography>18/09/2021</Typography>
        <Typography variant="caption" color="textSecondary">
          14:31
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" paddingLeft=".5rem">
        <Box>
          <Typography className={classes.textDescription}>
            <b>{enHistoryTypeLabel[type]}: </b> {renderHTML(message)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          py="4px"
          alignItems="center"
        >
          <Typography className={classes.authorText}>
            <b>{requester}: </b> {footerInfo}
          </Typography>
          <Typography onClick={onClickInfo} className={classes.link}>
            Ver mais
          </Typography>
        </Box>
      </Box>
      <Divider flexItem />
    </Box>
  );
}
