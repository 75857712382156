import {
  FormControl,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Heading } from "../Heading";
import { ForgotPasswordSchema } from "./formSchema";
import Input from "@material-ui/core/Input/Input";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Button from "@material-ui/core/Button/Button";
import Box from "@material-ui/core/Box";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ReactInputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import AuthenticateService from "../../services/api/authenticate";
import { enErrorNames } from "../../enums/error-names";
import { useNotificationContext } from "../../context/NotificationContext";
import CheckIcon from "@material-ui/icons/Check";
import { enNotificationTypes } from "../../enums/notification-types";
import clsx from "clsx";
import WarningIcon from "@material-ui/icons/Warning";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "hidden",
    background: "#0b366f",
    padding: theme.spacing(3),
  },

  spacing: {
    "& > div": {
      marginBottom: theme.spacing(3),
    },
  },

  fullWidth: {
    width: "100%",
  },
  inputLabel: {
    color: "#e5e5e5",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
    }),
    "&.Mui-focused": {
      color: theme.palette.background.paper,
      transform: "scale(1.1)"
    },
  },
  formChild: {
    background: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    "& .MuiInput-input": {
      paddingLeft: theme.spacing(2),
      height: "35px",
    },
  },

  subtitle: {
    color: "white",
    fontSize: ".8rem",
  },

  sendButton: {
    color: "white",
    borderColor: "#0059d6",
    "border-radius": "35px",
    width: "253px",
    height: "58px",
    "margin-bottom": theme.spacing(2),
  },

  backButton: {
    color: "white",
    fontSize: ".9rem",
  },

  success: {
    color: theme.palette.dialog[enNotificationTypes.SUCCESS].main,
  },
  error: {
    color: theme.palette.dialog[enNotificationTypes.ERROR].main,
  },
}));

export function PasswordForgot() {
  const classes = useStyles();
  const history = useHistory();
  const [enableButtonSendMail, setEnableButtonSendMail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addDialog } = useNotificationContext();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const onCpfComplete = useCallback(
    async (document) => {
      try {
        setIsLoading(true);
        const email = await AuthenticateService.getEmailByDocument(document);
        setValue("email", email, { shouldValidate: false, shouldDirty: false });
        setEnableButtonSendMail(true);
      } catch (error) {
        const isNotFountError = error.message.includes(
          enErrorNames.MODEL_NOT_FOUND
        );
        if (isNotFountError) {
          addDialog({
            type: enNotificationTypes.INFO,
            message: "Usuario não encontrado na base de dados.",
            title: "Usuário não cadastrado.",
          });
        } else {
          addDialog({
            type: enNotificationTypes.INFO,
            message: "",
            title: "Problema inesperado.",
          });
        }
        setValue("email", "", { shouldValidate: false, shouldDirty: false });
        setEnableButtonSendMail(false);
        setError("cpf", { message: "Usuário não encontrado." });
      } finally {
        setIsLoading(false);
      }
    },
    [addDialog, setValue, setError]
  );

  const cpf = watch("cpf", "");

  useEffect(() => {
    const execute = async () => {
      const isValidCpf = await trigger("cpf");
      if (isValidCpf) {
        return onCpfComplete(cpf);
      }

      if (!isValidCpf)
        setValue("email", "", { shouldValidate: false, shouldDirty: false });
    };
    execute();
  }, [cpf, onCpfComplete, trigger, setValue]);

  const Icon = useMemo(() => {
    if (isLoading) return CircularProgress;
    if (!!errors?.cpf?.message) return WarningIcon;
    if (enableButtonSendMail) return CheckIcon;

    return ArrowForwardIcon;
  }, [enableButtonSendMail, errors.cpf, isLoading]);

  const goBack = useCallback(() => {
    history.push("/login");
  }, [history]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await AuthenticateService.sendRecoveryPasswordRequest({
          document: data.cpf,
        });
        history.push("/recovery-success", { ...data });
      } catch (error) {
        console.error(error);
        addDialog({
          type: enNotificationTypes.INFO,
          message:
            "Houve um problema ao enviar o e-mail de redefinição de senha.",
          title: "E-mail não enviado!",
        });
      }
    },
    [history, addDialog]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={`${classes.root} ${classes.spacing}`}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <Heading>Esqueceu a senha?</Heading>
      </Box>
      <Box>
        <Typography variant="body2" className={classes.subtitle}>
          Insira seu CPF no campo a baixo para receber o link com a redefinição
          de senha que será enviado para o e-mail que foi registrado.
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        width="100%"
        className={classes.spacing}
      >
        <FormControl fullWidth>
          <InputLabel className={classes.inputLabel} htmlFor="cpf">
            CPF
          </InputLabel>
          <Controller
            control={control}
            mask="999.999.999-99"
            name="cpf"
            render={({ field, fieldState: { error } }) => (
              <ReactInputMask mask="999.999.999-99" {...field}>
                {(props) => (
                  <Input
                    error={!!error?.message}
                    fullWidth
                    className={classes.formChild}
                    placeholder="CPF"
                    type="tel"
                    endAdornment={
                      <IconButton
                        className={clsx({
                          [classes.success]: enableButtonSendMail,
                          [classes.error]: !!error?.message,
                        })}
                        size="medium"
                        variant="outlined"
                      >
                        <Icon />
                      </IconButton>
                    }
                    {...props}
                  />
                )}
              </ReactInputMask>
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel className={classes.inputLabel} htmlFor="email">
            E-mail
          </InputLabel>
          <Input
            readOnly
            error={!!errors.email}
            fullWidth
            className={classes.formChild}
            id="email"
            placeholder="E-mail"
            type="email"
            {...register("email")}
          />
        </FormControl>
      </Box>
      <Box display="flex" flexDirection="column">
        <Button
          disabled={!enableButtonSendMail && !!errors.cpf?.message}
          variant="outlined"
          size="medium"
          className={classes.sendButton}
          endIcon={<ArrowForwardIcon />}
          type="submit"
        >
          ENVIAR E-MAIL
        </Button>
        <Button onClick={goBack} className={classes.backButton}>
          VOLTAR
        </Button>
      </Box>
    </Box>
  );
}
