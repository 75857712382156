import React from "react";
import { Box, Divider, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  line: {
    background: ({ color }) => color,
    marginLeft: "25px",
    width: "25px",
  },
  header: {
    position: "relative",
    fontSize: "1.5rem",
    textTransform: "uppercase",
  },
}));

export function Heading({ children, variant = "h6", color = "#fff", ...others }) {
  const classes = useStyles({ color });

  return (
    <Box
      className={classes.header}
      display="flex"
      alignItems="center"
      flexDirection="column"
      color={color}
    >
      <Typography component="h2" variant={variant} color="inherit" {...others}>
        {children}
      </Typography>
      <Divider className={classes.line} />
    </Box>
  );
}
