import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DoneIcon from "@material-ui/icons/Done";
import { Pictures, CardImage } from "../PictureShow";
import { useDisclosure } from "../../hooks/useDisclosure";
import { PictureModal } from "./PictureModal";
import LeafletMap from "./LeafletMap";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import { enUserRoles } from "../../enums/user-roles";
import ReactInputMask from "react-input-mask";

const useStyles = makeStyles((theme) => ({
  map: {
    height: "327px",
    width: "100%",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.text.secondary,
  },

  formSessionHeader: {
    fontWeight: 700,
    fontSize: ".9rem",
    textTransform: "capitalize",
    color: theme.palette.primary.dark,
  },

  moreDate: {
    fontSize: ".9rem",
    padding: "6px 35px",
    letterSpacing: ".15em",
  },

  finalize: {
    fontSize: ".75rem",
    lineHeight: "1.2em",
    textAlign: "center",
    padding: theme.spacing(1, 2),
    color: theme.palette.background.paper,
    background: theme.palette.secondary.light,
  },
}));

function DemandForm({
  demand = {},
  formState: state = "onlyRead",
  onInsertData,
  onFinish,
}) {
  const classes = useStyles();
  const { register, reset, control } = useForm({
    defaultValues: { ...demand },
  });
  const fieldsState = useMemo(() => state === "onlyRead", [state]);
  const [selectedPic, setSelectedPic] = useState("");
  const demandPictures = useMemo(
    () => demand.attachments || [],
    [demand.attachments]
  );

  const {
    isOpen: isModalPictureOpen,
    open: openPictureModal,
    close: closePictureModal,
  } = useDisclosure(false);

  const handleClickPicture = useCallback(
    (pic) => {
      setSelectedPic(pic.url);
      openPictureModal();
    },
    [openPictureModal]
  );

  useEffect(() => {
    reset({ ...demand });
  }, [demand, reset]);

  const geoPosition = useMemo(
    () => [demand.location?.lng || 0, demand.location?.lat || 0],
    [demand.location]
  );

  return (
    <Box width="100%" component="form" px="8px">
      <Typography className={classes.formSessionHeader}>Localização</Typography>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        padding="4px 8px"
        px="8px"
      >
        <TextField
          label="Bairro"
          placeholder="Bairro"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          {...register("neighborhood")}
          InputProps={{
            readOnly: fieldsState,
          }}
        />

        <TextField
          label="Logradouro"
          placeholder="Logradouro"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          {...register("location.address")}
          variant="outlined"
          InputProps={{
            readOnly: fieldsState,
          }}
        />
      </Box>
      <Typography className={classes.formSessionHeader}>
        Geolocalizaçao
      </Typography>
      <LeafletMap position={geoPosition} />
      <Typography className={classes.formSessionHeader}>Dados</Typography>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        padding="4px 8px"
        px="8px"
      >
        <TextField
          label="Serviço solicitado"
          placeholder="Serviço solicitado"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          {...register("service.name")}
          InputProps={{
            readOnly: fieldsState,
          }}
        />

        <TextField
          label="Descrição"
          placeholder="Descrição"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          {...register("description")}
          InputProps={{
            readOnly: fieldsState,
          }}
        />

        <Pictures shouldRenderFallback={!demandPictures.length}>
          {demandPictures.map((image) => (
            <CardImage
              isDeletable={false}
              onClick={() => handleClickPicture(image)}
              key={image.id}
              fileName={image.fileName}
              src={image.url}
              id={image.id}
            />
          ))}
        </Pictures>
      </Box>
      <Typography className={classes.formSessionHeader}>Solicitante</Typography>
      <Box width="100%" padding="4px 8px">
        <TextField
          label="Nome"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          {...register("requester.name")}
          InputProps={{
            readOnly: fieldsState,
          }}
        />

        <Controller
          control={control}
          mask="(99) 99999-9999"
          name="requester.phone"
          render={({ field }) => (
            <ReactInputMask
              mask="(99) 99999-9999"
              {...field}
              disabled={!demand.requester?.phone}
              readOnly
            >
              {(props) => (
                <TextField
                  label="Telefone de contato"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        disabled={!demand.requester?.phone}
                        component="a"
                        href={`tel:+55${demand.requester?.phone || ""}`}
                      >
                        <CallIcon />
                      </IconButton>
                    ),
                    ...props,
                  }}
                />
              )}
            </ReactInputMask>
          )}
        />
        <TextField
          label="Email"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          {...register("requester.email")}
          InputProps={{
            readOnly: fieldsState,
            endAdornment: (
              <IconButton
                component="a"
                href={`mailto:${demand.requester?.email || ""}`}
              >
                <EmailIcon />
              </IconButton>
            ),
          }}
        />
        <Controller
          control={control}
          name="requester.role"
          render={({ field: { value, ...rest } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={value === enUserRoles.PUBLIC_PERSON}
                  {...rest}
                />
              }
              label="Pessoa pública?"
              labelPlacement="start"
            />
          )}
        />
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        height="100px"
        justifyContent="space-around"
      >
        <Button onClick={onInsertData} color="primary">
          Inserir dados
        </Button>
        <Button onClick={onFinish} startIcon={<DoneIcon />} color="secondary">
          Finalizar
        </Button>
      </Box>
      <PictureModal
        open={isModalPictureOpen}
        onClose={closePictureModal}
        src={selectedPic}
        alt="selected image"
      />
    </Box>
  );
}

export default DemandForm;
