import * as yup from "yup";

export const serviceSchema = yup.object().shape({
  name: yup.string(),
  _id: yup.string()
});

export const filterValidator = {
  intervalStart: yup.date(),
  intervalEnd: yup.date(),

  services: yup.array().of(serviceSchema).min(1),
  status: yup.array().of(yup.string()).min(1),
  publicPerson: yup.bool(),
  fisicPerson: yup.bool(),
  requesterName: yup.string(),
  address: yup.string(),
}

export async function  getOnlyValidProps(filters) {
  const props = Object.keys(filterValidator)
  const proms = props.map(async (key) => {
    const filterPropValue = filters[key];
    const exists = !!filterPropValue;
    if(!exists) return exists;

    const validator = filterValidator[key];
    const isValid = await validator.isValid(filterPropValue);

    if(!isValid) return isValid;

    return key;
  });

  const results = await Promise.all(proms);
  const validProps = results.filter(el => !!el);
  const validObject = validProps.reduce((memo, key) => {
    memo[key] = filters[key];
    return memo;
  }, {});

  
  return validObject
}

export const filterSchemaValidator = yup.object().shape({
  intervalStart: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
  intervalEnd: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),

  services: yup.array().of(serviceSchema).optional(),
  status: yup.array().of(yup.string()).optional(),
  publicPerson: yup.bool().optional(),
  requesterName: yup.string().optional(),
  address: yup.string().optional(),
});
