import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { AppEventsManager } from "../services/emitter";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { CssBaseline } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 2,
    marginLeft: "-52px",
  },

  textCenter: {
    textAlign: "center",
  },
}));

export default function AppBarComponent({
  title = "",
  children,
  position = "relative",
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onShowMenu = useCallback((evt) => {
    AppEventsManager.emit("menu-toggle");
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    AppEventsManager.emit("logout");
    handleClose();
  };

  return (
    <>
      <CssBaseline />
      <AppBar position={position} color="primary">
        <Toolbar className={classes.textCenter}>
          <IconButton
            onClick={onShowMenu}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
        {children}
      </AppBar>
    </>
  );
}
