import { Box, Button, Link, makeStyles } from "@material-ui/core";
import { Control } from "leaflet";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useEventHandlers,
} from "react-leaflet";
import { useUserPosition } from "../../hooks/useUserPosition";

const useStyles = makeStyles((themes) => ({
  root: {
    padding: themes.spacing(1),
  },
  mapContainer: {
    width: "100%",
    height: "30vh",
  },
  gMapsBtn: {
    position: "absolute",
    zIndex: "1000",
    right: "0px",
    bottom: "20px",
    backgroundColor: " rgba(255, 255, 255, 0.7)",
    fontSize: "11px",
    padding: "0 3px",
  },
}));

const GMapLink = ({ position }) => {
  const classes = useStyles();

  const gUrl = useMemo(
    () =>
      `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${position[0]},${position[1]}`,
    [position]
  );

  return (
    <Box className={classes.gMapsBtn}>
      <Link href={gUrl} target="_blanck">
        Abrir no Google Maps
      </Link>
    </Box>
  );
};

const requestGeolocationOptions = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000,
};

function MapController({ position }) {
  const map = useMap();

  useEffect(() => {
    map.flyTo(position, map.getZoom());
  }, [position, map]);
  return null;
}

function LeafletMap({ position }) {
  const classes = useStyles();
  const userPosition = useUserPosition(requestGeolocationOptions);
  const map = useRef(null);

  useEffect(() => {
    console.log(userPosition.latLong);
  }, [userPosition.latLong]);
  return (
    <MapContainer
      center={userPosition.latLong}
      zoom={13}
      scrollWheelZoom={false}
      className={classes.mapContainer}
      whenCreated={(mapInstance) => {
        map.current = mapInstance;
      }}
    >
      <Marker position={position}>
        <Popup>Este é o local do serviço</Popup>
      </Marker>
      <Marker position={userPosition.latLong}>
        <Popup>Sua posição atual</Popup>
      </Marker>
      <GMapLink position={position} />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MapController position={position} />
    </MapContainer>
  );
}

export default LeafletMap;
