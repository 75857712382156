import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperFullScreen": {
      width: "100%",
      height: ({ height }) => height,
      margin: "0",
      maxWidth: "100%",
      maxHeight: "none",
      borderRadius: "0",
      bottom: "0",
      position: "absolute",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function HalfwayDialog({
  open,
  handleClose,
  children,
  height = "70%",
  ...others
}) {
  const classes = useStyles({ height });

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={classes.root}
      {...others}
    >
      {children}
    </Dialog>
  );
}
