import React, { useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { alpha, Box, makeStyles } from "@material-ui/core";
import { enNotificationTypes } from "../../enums/notification-types";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  primaryRegion: {
    backgroundColor: ({ type }) => theme.palette.dialog[type].main,
    color: ({ type }) =>
      theme.palette.getContrastText(theme.palette.dialog[type].main),
  },
  secondaryRegion: {
    backgroundColor: ({ type }) => theme.palette.dialog[type].secondary,
    color: ({ type }) =>
      theme.palette.getContrastText(theme.palette.dialog[type].secondary),
  },

  primaryRegionButton: {
    backgroundColor: ({ type }) => alpha(theme.palette.dialog[type].main, .5),
    color: ({ type }) =>
      theme.palette.getContrastText(theme.palette.dialog[type].main),
  },
  secondaryRegionButton: {
    backgroundColor: ({ type }) => alpha(theme.palette.dialog[type].secondary, .3),
    color: ({ type }) =>
      theme.palette.getContrastText(theme.palette.dialog[type].secondary),
  },
}));

const iconMapper = {
  [enNotificationTypes.INFO]: WarningRoundedIcon,
  [enNotificationTypes.ERROR]: ErrorRoundedIcon,
  [enNotificationTypes.SUCCESS]: CheckCircleIcon,
  [enNotificationTypes.INFO]: InfoRoundedIcon,
};

export function InteractionDialog(props) {
  const {
    open,
    message,
    type,
    title,
    showCancelButton,
    onClickOk,
    onClickCancel,
    okText,
    cancelText,
  } = props;

  const classes = useStyles({ type });
  const Icon = useMemo(() => iconMapper[type], [type]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <Box
        display="flex"
        id="alert-dialog-title"
        alignItems="center"
        padding="0 12px"
        className={classes.primaryRegion}
      >
        <Icon />
        <DialogTitle>{title}</DialogTitle>
      </Box>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {showCancelButton && (
          <Button
            className={classes.secondaryRegionButton}
            onClick={onClickCancel}
          >
            {cancelText}
          </Button>
        )}
        <Button
          className={classes.primaryRegionButton}
          onClick={onClickOk}
          autoFocus
        >
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
