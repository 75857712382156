import Environment from "../entities/Environment";
import ApiNotOk from "../entities/errors/apiNotOk";
import ApiError from "../entities/errors/apiError";
import { AppEventsManager } from "./emitter";
import { enAuthEvents } from "../enums/auth-events";
import { AUTH_STORAGE_KEY } from "../entities/Constants";

export default class AbstractApiService {
  static base = `${Environment.get()}`;
  static get defaultHeaders() {
    const bearerToken = `Bearer ${localStorage.getItem(AUTH_STORAGE_KEY)}`;
    const defaultHeaders = {
      "Content-Type": "application/json",
      "x-requested-with": "SERVICE_PROVIDER",
    };

    if (bearerToken) {
      defaultHeaders["x-access-token"] = bearerToken;
    }

    return defaultHeaders;
  }

  static async fetch(url, params = {}) {
    const response = await fetch(`${this.base}${url}`, {
      headers: AbstractApiService.defaultHeaders,
      ...params,
    });

    if (!response.ok) {
      ApiNotOk.throwError();
    }

    const result = await response.json();
    if (result.error) {
      if (result.message.includes("Token Wrong")) {
        const error = new Error("Problemas com autenticação");
        error.type = "error";
        error.title = "Token expirado";

        AppEventsManager.emit(enAuthEvents.FORCE_LOGOUT, error);
      }
      ApiError.throwError(`${result.message} - ${result.error}`);
    }

    response.data = result;

    return response;
  }
}
