import React, { useEffect } from "react";
import LoginForm from "../../components/Login";
import { useAuth } from "../../context/AuthContext";

function Login() {
  const { logout } = useAuth();

  useEffect(() => {
    logout()
  }, [logout]);

  return <LoginForm />;
}

export default Login;
