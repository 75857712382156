import React from "react";
import LoginPage from "../pages/Login";
import { PasswordRecovery } from "../pages/PasswordRecovery";
import { PasswordRecoverySuccess } from "../pages/PasswordRecovery/PasswordRecoverySuccess";
import { Demands } from "../pages/Demands";
import DemandDetailsPage from "../pages/DemandDetails";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import Route from "../components/Router";

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/password-recovery" component={PasswordRecovery} />
        <Route path="/recovery-success" component={PasswordRecoverySuccess} />
        <Route path="/home" component={Demands} isPrivate />
        <Route
          path="/demand/:demandId"
          component={DemandDetailsPage}
          isPrivate
        />
        <Redirect to="/login"/>
      </Switch>
    </Router>
  );
}
