import { Box, Icon, makeStyles, Typography } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { useHistory } from "react-router-dom";
import { enUserRoles } from "../../enums/user-roles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    flexDirection: "row",
    width: "95%",
    margin: theme.spacing(1),
    minHeight: "10vh",
  },

  serviceName: {
    fontWeight: 500,
    fontSize: ".9rem",
    color: theme.palette.primary.dark,
    textTransform: "capitalize",
    letterSpacing: ".53px",
  },

  status: {
    paddingTop: theme.spacing(1),
    fontWeight: 800,
    fontSize: ".6rem",
    color: theme.palette.primary.dark,
    textTransform: "uppercase",
    letterSpacing: ".53px",
  },

  address: {
    letterSpacing: ".4px",
    color: theme.palette.text.secondary,
    fontSize: ".8rem",
    lineHeight: "1em",
  },

  publicPerson: {
    fontWeight: 800,
    letterSpacing: ".3px",
    fontSize: ".45rem",
    textTransform: "uppercase",
  },
}));

function RequestCard({
  _id,
  name,
  location: { address },
  status,
  service,
  requester,
  description,
}) {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = useCallback(() => {
    history.push(`/demand/${_id}`);
  }, [_id, history]);

  const isPublicPerson = useMemo(
    () => requester.role === enUserRoles.PUBLIC_PERSON,
    [requester.role]
  );

  return (
    <Box className={classes.root} onClick={handleClick}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingX={1}
        width="20%"
      >
        <Icon fontSize="medium" >{service.icon}</Icon>
        <Typography className={classes.status}>{status.private}</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="80%"
        justifyContent="center"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={`${classes.serviceName}`}>
            {service.name}
          </Typography>
          {isPublicPerson && (
            <Typography color="secondary" className={classes.publicPerson}>
              Pessoa publica
            </Typography>
          )}
        </Box>
        <Box>
          <Typography className={classes.address} >{description}</Typography>
          <Typography className={classes.address}>{address}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default RequestCard;
