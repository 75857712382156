import React from "react";
import "./App.css";
import Routes from "./routes";
import { MainTheme } from "./themes/MainTheme";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import { AuthProvider } from "./context/AuthContext";
import { NotificationContextProvider } from "./context/NotificationContext";
import { NotificationContextConsumer } from "./components/Consumers/NotificationContextConsumer";
import { AppDependenciesFactory } from "./factories/appDependecies";
import { FcmContextProvider } from "./push-notifications/fcm/fcm-context";

const {fcmManager} = AppDependenciesFactory();

moment.locale("pt-br");

function App() {
  return (
    <AuthProvider messagingManager={fcmManager}>
      <ThemeProvider theme={MainTheme}>
        <FcmContextProvider messagingManager={fcmManager}>
          <NotificationContextProvider>
            <Routes />
            <NotificationContextConsumer />
          </NotificationContextProvider>
        </FcmContextProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
