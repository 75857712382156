import moment from "moment";

export class FcmManager {
  static TOKEN_KEY = "local-storage-fcm-key";
  static ASK_LOCAL = "local-storage-ask";

  constructor(messaging, services) {
    this.messaging = messaging;
    this.services = services;
  }

  getTokenExpirationDate() {
    const expirationDateString = localStorage.getItem(FcmManager.TOKEN_KEY);
    if (!expirationDateString) return false;

    const expirationDate = JSON.parse(expirationDateString);
    return new Date(expirationDate);
  }

  async sendTokenToServer(token) {
    const expirationDate = moment().add(1, "month").toDate();
    const isSucceed = await this.services.authService.updateNotificationToken({
      token,
      expiration: expirationDate,
    });

    if (isSucceed) this.setExpirationDate(expirationDate);
  }

  setExpirationDate(date) {
    const dateString = JSON.stringify(date);
    window.localStorage.setItem(FcmManager.TOKEN_KEY, dateString);
  }

  isTokenValid() {
    const expirationDate = this.getTokenExpirationDate();
    if (!expirationDate) return false;
    
    const isExpired = expirationDate.getTime() < Date.now();

    return !isExpired;
  }

  getMessaging() {
    return this.messaging;
  }

  async requestNotificationAuth() {
    const hasNotificationApi = !!window.Notification;
    if (!hasNotificationApi)
      throw new Error("Seu navegador não suporta push notifications.");

    const permission = await window.Notification.requestPermission();
    const isPermissionGranted = permission === "granted";

    return isPermissionGranted;
  }

  shouldAsk() {
    const stringValue = localStorage.getItem(FcmManager.TOKEN_KEY);
    if (!stringValue) return true;

    return JSON.parse(stringValue);
  }

  setAskNotification(value = true) {
    const stringValue = JSON.stringify(value);
    return localStorage.setItem(FcmManager.TOKEN_KEY, stringValue);
  }
}
