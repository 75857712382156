import {
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Link as MuiLink,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { cpf } from "../Masks/cpf";
import ReactInputMask from "react-input-mask";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "8px 0px",
  },
  loginButton: {
    width: "80%",
    height: "45px",
    borderRadius: "35px",
    color: blue[800],
    backgroundColor: theme.palette.background.paper,
  },

  inputIcon: {
    color: theme.palette.text.secondary,
  },

  inputLabel: {
    color: "#fff",
    fontSize: "1.1rem",
  },

  secondaryText: {
    color: "#eee",
    fontSize: ".8rem",
  },

  formChild: {
    backgroundColor: "#fff",
    padding: theme.spacing(0, 1, 0, 1),
    height: "36px",
  },

  item: {
    marginTop: `${theme.spacing(2)}px`,
  },

  iconSecondary: {
    color: theme.palette.text.secondary,
  },

  link: {
    "&.MuiLink-underlineHover": {
      textDecoration: "underline white",
    },
  },
  passwordField: {
    "-webkit-text-security": "disc",
  },

  inputHelperError: {
    color: theme.palette.error.dark,
    "margin:": "0",
    "font-size": "0.75rem",
    "margin-top": "3px",
    "text-align": "left",
    "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
    "font-weight": "400",
    "line-height": "1.66",
    "letter-spacing": "0.03333em",
  },
}));

const loginSchema = yup.object().shape({
  password: yup.string().required("Preencha o campo de senha"),
  cpf: yup
    .string()
    .test("isValidCpf", "Formato de CPF inválido", cpf.isValid)
    .required(),
});

function LoginForm({ onSubmitLogin, isLoading }) {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  return (
    <Grid
      className={classes.root}
      container
      component="form"
      noValidate
      autocomplete="no"
      onSubmit={handleSubmit(onSubmitLogin)}
      alignContent="center"
      justifyContent="center"
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        className={classes.item}
      >
        <FormControl>
          <InputLabel className={classes.inputLabel} htmlFor="email">
            CPF
          </InputLabel>
          <Controller
            control={control}
            mask="999.999.999-99"
            name="cpf"
            render={({ field, fieldState: { error } }) => (
              <ReactInputMask mask="999.999.999-99" {...field}>
                {(props) => (
                  <Input
                    error={!!error?.message}
                    fullWidth
                    className={classes.formChild}
                    placeholder="CPF"
                    variant="filled"
                    type="tel"
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon className={classes.iconSecondary} />
                      </InputAdornment>
                    }
                    {...props}
                  />
                )}
              </ReactInputMask>
            )}
          />
          {errors.email && (
            <span className={classes.inputHelperError}>
              {errors.email.message}
            </span>
          )}
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        className={classes.item}
      >
        <FormControl>
          <InputLabel className={classes.inputLabel} htmlFor="password">
            Senha
          </InputLabel>
          <Input
            error={!!errors.password}
            variant="filled"
            id="password"
            placeholder="Senha"
            className={`${classes.passwordField} ${classes.formChild}`}
            {...register("password", { required: true })}
            startAdornment={
              <InputAdornment position="start">
                <VpnKeyIcon className={classes.iconSecondary} />
              </InputAdornment>
            }
          />
          {errors.password && (
            <span className={classes.inputHelperError}>
              {errors.password.message}
            </span>
          )}
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        style={{ marginTop: 8 }}
      >
        <Typography variant="caption" className={classes.secondaryText}>
          Esqueceu sua senha?&nbsp;
        </Typography>
        <MuiLink
          component={Link}
          className={`${classes.secondaryText} ${classes.link}`}
          to="/password-recovery"
        >
          Clique aqui
        </MuiLink>
      </Grid>
      <Grid
        item
        container
        xs={12}
        alignContent="center"
        justifyContent="center"
        style={{ marginTop: 60 }}
      >
        <Button
          fullWidth
          variant="contained"
          className={classes.loginButton}
          disabled={isLoading}
          type="submit"
        >
          Entrar
        </Button>
      </Grid>
    </Grid>
  );
}

export default LoginForm;
