import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNotificationContext } from "../../context/NotificationContext";
import { enNotificationTypes } from "../../enums/notification-types";
import { Heading } from "../Heading";
import LoginForm from "./LoginForm";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    background: "#0b366f",
    padding: "30px",
  },
  loginForm: {
    background: "#ffffff55",
    padding: "12px",
    borderRadius: "8px",
  },

  line: {
    background: "white",
    marginLeft: "25px",
    width: "25px",
  },

  header: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    color: theme.palette.getContrastText("#0b366f"),
  },
}));

function Login() {
  const classes = useStyles();
  const { login } = useAuth();
  const { addDialog } = useNotificationContext();
  const doLogin = useCallback(
    async (data) => {
      try {
        await login({
          document: data.cpf,
          password: data.password,
        });
      } catch (error) {
        addDialog({
          message: error.message,
          title: "Erro ao realizar login",
          type: enNotificationTypes.ERROR,
        });
      }
    },
    [login, addDialog]
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-evenly"
      className={classes.root}
    >
      <Grid item container justifyContent="center" className={classes.header}>
        <Heading variant="h5">Entrar</Heading>
      </Grid>
      <Grid item className={classes.loginForm}>
        <LoginForm onSubmitLogin={doLogin} isLoading={false} />
      </Grid>
    </Grid>
  );
}

export default Login;
