import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import jwtDecode from "jwt-decode";
import { AppEventsManager } from "../services/emitter";
import { enAuthEvents } from "../enums/auth-events";
import AuthenticateService from "../services/api/authenticate";
import { AUTH_STORAGE_KEY } from "../entities/Constants";

export const getTokenSync = () => localStorage.getItem(AUTH_STORAGE_KEY);
export const AuthContext = createContext({});

export const AuthProvider = ({ children, messagingManager }) => {
  const [token, setToken] = useState(getTokenSync);
  const user = useMemo(() => {
    try {
      return jwtDecode(token);
    } catch (error) {
      return "";
    }
  }, [token]);
  
  const isAuthenticated = useMemo(() => !!user, [user]);

  const onTokenChange = useCallback((tokenArg) => {
    try {
      jwtDecode(tokenArg);
      localStorage.setItem(AUTH_STORAGE_KEY, tokenArg);
      setToken(tokenArg);
      AppEventsManager.emit(enAuthEvents.LOGIN_CHANGE, tokenArg);
    } catch (err) {
      AppEventsManager.emit(enAuthEvents.LOGIN_ERROR, tokenArg);
    }
  }, []);

  const login = useCallback(
    async (userCredentials) => {
      const response = await AuthenticateService.login(userCredentials);
      onTokenChange(response.data.token);

      try {
        const userAllowNotifications = await messagingManager.requestNotificationAuth();
        if(!userAllowNotifications) return;
  
        const fcmToken = await messagingManager.getMessaging().getToken();
        await messagingManager.sendTokenToServer(fcmToken);
      } catch (error) {
        console.error(error);
        error.message = "Não é possivel enviar notificações"
        throw error;
      }
    },
    [onTokenChange]
  );

  const logout = useCallback(() => {
    setToken(null);
    localStorage.removeItem(AUTH_STORAGE_KEY);
    AppEventsManager.emit(enAuthEvents.LOGIN_CHANGE, null);
  }, []);

  useEffect(() => {
    AppEventsManager.on(enAuthEvents.FORCE_LOGOUT, logout);
    return () => AppEventsManager.off(enAuthEvents.FORCE_LOGOUT, logout)
  }, [logout]);



  const contextState = useMemo(
    () => ({
      user,
      login,
      logout,
      isAuthenticated,
    }),
    [user, login, logout, isAuthenticated]
  );

  return (
    <AuthContext.Provider value={contextState}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
