import { Box, makeStyles, Button } from "@material-ui/core";
import React, { useCallback } from "react";
import Bar from "@material-ui/core/AppBar";
import SortIcon from "@material-ui/icons/Sort";
import clsx from "clsx";
import { enOrder, enOrderType } from "../../enums";

const useStyles = makeStyles((theme) => ({
  spacing: {
    opacity: 0,
    marginRight: theme.spacing(1),
  },
  orderDesc: {
    transform: "rotate(-180deg)",
    transition: " .3s ",
    animation: "$fadeInFromNone 1s ease-out",
    animationFillMode: "forwards",
  },

  orderAsc: {
    transform: "rotate(0 deg)",
    transition: " .3s ",
    animation: "$fadeInFromNone 1s ease-out",
    animationFillMode: "forwards",
  },

  activeMarker: {
    left: ({ offset }) => `${offset * 33.333}%`,
    bottom: "0",
    width: "33%",
    height: "2px",
    position: "absolute",
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.complex,
    }),
  },
  deselectedButton: {},
  activeButton: {
    opacity: 1,
    color: theme.palette.secondary.main,
  },

  "@keyframes fadeInFromNone": {
    from: { opacity: 0, display: 'none'},
    to: { opacity: 1, display: 'block'},
  },
}));

const OrderButton = ({ isSelected, order, children, ...others }) => {
  const classes = useStyles();
  const sortingClasses = clsx(classes.spacing, {
    [classes.orderAsc]: order === enOrder.ASC && isSelected,
    [classes.orderDesc]: order === enOrder.DESC && isSelected,
  });
  const buttonClasses = clsx(classes.deselectedButton, {
    [classes.activeButton]: isSelected,
  });

  return (
    <Button
      fullWidth
      startIcon={<SortIcon className={sortingClasses} fontSize="small" />}
      className={buttonClasses}
      {...others}
    >
      {children}
    </Button>
  );
};

export function OrderTabs({ value, onChange, children, order }) {
  const classes = useStyles({ offset: value });
  const handleChange = useCallback(
    (selectedIndex) => {
      onChange(selectedIndex);
    },
    [onChange]
  );

  return (
    <Bar position="relative" color="default">
      <Box width="100%" display="flex" position="relative">
        <OrderButton
          fullWidth
          order={order}
          isSelected={value === enOrderType.DATA}
          onClick={() => handleChange(enOrderType.DATA)}
        >
          DATA
        </OrderButton>
        <OrderButton
          fullWidth
          order={order}
          isSelected={value === enOrderType.BAIRRO}
          onClick={() => handleChange(enOrderType.BAIRRO)}
        >
          BAIRRO
        </OrderButton>
        <OrderButton
          fullWidth
          order={order}
          isSelected={value === enOrderType.STATUS}
          onClick={() => handleChange(enOrderType.STATUS)}
        >
          STATUS
        </OrderButton>
        <span className={classes.activeMarker} />
      </Box>
      {children}
    </Bar>
  );
}
