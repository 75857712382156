class Environment {

  static get() {
    const HOST = window.location.hostname;

    if( HOST.includes('localhost') ) {
      return "http://localhost:3100";
    }

    if(HOST.includes('demanda-prefeitura-empresa-web-homolog.s3')){
      return 'http://prefeitura-demanda-api.cerconsultoria.com';
    }

    if(HOST.includes('prefeitura-demanda-empresa.cerconsultoria')){
      return 'https://prefeitura-demanda-api.cerconsultoria.com';
    }

    throw new Error("HOST inválido!");
  }

}


export default Environment;
