import moment from "moment";

export function demandFilterApiDtoParser(rawFilters) {
  const servicesFilter = rawFilters?.services?.map((service) => service._id);
  const intervalStart =
    rawFilters?.intervalStart &&
    moment(rawFilters.intervalStart).startOf("day").toDate();
  const intervalEnd =
    rawFilters?.intervalEnd &&
    moment(rawFilters.intervalEnd).endOf("day").toDate();

  return {
    intervalEnd,
    intervalStart,
    services: servicesFilter,
    address: rawFilters.address,
    fisicPerson: rawFilters.fisicPerson,
    "status.private": rawFilters.status,
    publicPerson: rawFilters.publicPerson,
    requesterName: rawFilters.requesterName,
  };
}
