import * as yup from "yup";
import { cpf } from "../Masks/cpf";

export const ForgotPasswordSchema = yup.object().shape({
  cpf: yup.string().test(
    'isValidCpf',
    'Formato de CPF inválido',
    cpf.isValid
  ).required(),
  email: yup.string().email("Deve ser um email válido!"),
});
