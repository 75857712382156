import {
  AppBar,
  Box,
  Button,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import { HalfwayDialog } from "../DinamicHeightDialog";
import { FilterForm } from "../RequestFilters";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormatClearIcon from "@material-ui/icons/FormatClear";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 1),
  },
  buttonFilter: {
    background: theme.palette.background.default,
    borderRadius: "16px",
    boxShadow: theme.shadows[3],
    color: theme.palette.primary.dark,
  },

  title: {
    flexGrow: 1,
    textAlign: "center",
  },
}));

export function FilterButton({ onClick }) {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      display="flex"
      alignContent="flex-end"
      justifyContent="flex-end"
      className={classes.root}
    >
      <Button
        variant="contained"
        startIcon={<FilterListIcon />}
        onClick={onClick}
        className={classes.buttonFilter}
      >
        Filtrar
      </Button>
    </Box>
  );
}

export function FilterModal({
  open,
  onClose,
  filters,
  handleFilterChange,
  onReset,
}) {
  const classes = useStyles();
  const reset = React.useRef(null);

  const handleReset = React.useCallback(() => {
    onReset();
  }, [onReset]);

  return (
    <HalfwayDialog open={open} handleClose={onClose} height="100%" keepMounted scroll="paper">
      <AppBar color="default" position="fixed">
        <Toolbar className={classes.toolbar}>
          <IconButton
            onClick={onClose}
            edge="start"
            aria-label="menu"
            color="secondary"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography component="h6" variant="h6" className={classes.title}>
            FILTROS
          </Typography>

          <Button
            size="small"
            startIcon={<FormatClearIcon size="small" />}
            color="secondary"
            onClick={handleReset}
          >
            Limpar
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        height="100%"
        width="100%"
      >
        <FilterForm
          filters={filters}
          onChange={handleFilterChange}
          onCancel={onClose}
          resetRef={reset}
        />
      </Box>
    </HalfwayDialog>
  );
}
