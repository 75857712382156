import React, { useCallback, useRef, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { PictureModePicker } from "../../PictureShow";
import { DemandInfoForm } from "./DemandInfoForm";
import ImageUploading from "react-images-uploading";
import { Box, useTheme } from "@material-ui/core";
import { PictureModal } from "../PictureModal";
import { useDisclosure } from "../../../hooks/useDisclosure";
import { Heading } from "../../Heading";

const enAreas = {
  DEMAND_FORM: 0,
  PICK_MODE: 1,
};

const enPickMode = {
  CHOOSE: false,
  CAPTURE: "environment",
};

function AppendDemandInfoForm({ onSubmit, onCancel, status }) {
  const [images, setImages] = useState([]);
  const [area, setArea] = useState(enAreas.DEMAND_FORM);
  const [selectedPicture, setSelectedPicture] = useState("");
  const theme = useTheme();

  const { close, isOpen } = useDisclosure(false);

  const pickMode = useRef(enPickMode.CHOOSE);

  const setAreaDemandForm = useCallback(() => setArea(enAreas.DEMAND_FORM), []);
  const setAreaPickMode = useCallback(() => setArea(enAreas.PICK_MODE), []);
  const onChangePictures = useCallback((imageList) => setImages(imageList), []);

  return (
    <Box padding="12px 4px">
      <Heading color={theme.palette.primary.main}>Adicionar histórico</Heading>
      <ImageUploading
        multiple
        maxNumber={3}
        value={images}
        onChange={onChangePictures}
        acceptType={["jpg", "png"]}
        inputProps={{ capture: pickMode.current }}
      >
        {({ imageList, onImageUpload, onImageRemoveAll, onImageRemove }) => (
          <SwipeableViews index={area}>
            <Box>
              <DemandInfoForm
                historyStatus={status}
                images={imageList}
                onImageUpload={setAreaPickMode}
                onImageRemove={onImageRemove}
                onSubmit={onSubmit}
                handleSelectImage={setSelectedPicture}
                onCancel={onCancel}
              />
            </Box>

            <Box>
              <PictureModePicker
                onClickBack={setAreaDemandForm}
                onClickChoosePicture={() => {
                  pickMode.current = enPickMode.CHOOSE;
                  setTimeout(onImageUpload, 200);
                  setAreaDemandForm();
                }}
                onClickTakePicture={() => {
                  pickMode.current = enPickMode.CAPTURE;
                  setTimeout(onImageUpload, 200);
                  setAreaDemandForm();
                }}
                onClickExcludePicture={onImageRemoveAll}
              />
            </Box>
          </SwipeableViews>
        )}
      </ImageUploading>
      <PictureModal
        open={isOpen}
        onClose={close}
        src={selectedPicture}
        alt="selected image"
      />
    </Box>
  );
}

export default AppendDemandInfoForm;
