import React from "react";
import { makeStyles, Paper } from "@material-ui/core";

import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import { ResumedItemDescription } from "./ResumedItemDescription";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  item: {
    "&.MuiTimelineItem-missingOppositeContent:before": {
      display: "none",
    },
  },

  dotSeparator: {
    backgroundColor: ({ isPublic }) => (isPublic ? green[200] : red[200]),
  },
}));

export function HistoryItem({
  onClickInfo,
  showInfoBtn = true,
  ...historyItem
}) {
  const classes = useStyles({ isPublic: !!historyItem.isPublic });

  return (
    <TimelineItem className={classes.item}>
      <TimelineSeparator>
        <TimelineDot className={classes.dotSeparator}>
          <AssessmentOutlinedIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <ResumedItemDescription
          {...historyItem}
          onClickInfo={onClickInfo}
          showInfoBtn={showInfoBtn}
        />
      </TimelineContent>
    </TimelineItem>
  );
}
