import { blue, green, indigo, red, yellow } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import { enNotificationTypes } from "../enums/notification-types";

const palette = {
  primary: {
    dark: indigo[900],
    main: indigo[700],
    light: indigo[300],
  },
  secondary: {
    dark: "#1154A9",
    main: "#5288CD",
    light: "#77A5DF",
  },
  dialog: {
    [enNotificationTypes.INFO]: {
      main: blue[400],
      secondary: blue.A100,
    },
    [enNotificationTypes.SUCCESS]: {
      main: green[400],
      secondary: green.A100,
    },
    [enNotificationTypes.ERROR]: {
      main: red[500],
      secondary: red.A100,
    },
    [enNotificationTypes.WARNING]: {
      main: blue[400],
      secondary: blue.A100,
    },
  },
};

export const MainTheme = createTheme({ palette });
