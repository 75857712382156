import { Box, useTheme } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { useDisclosure } from "../../hooks/useDisclosure";
import { PictureModal } from "../DemandDetails/PictureModal";
import { HalfwayDialog } from "../DinamicHeightDialog";
import { Heading } from "../Heading";
import { CardImage, Pictures } from "../PictureShow";
import ItemDescription from "./ItemDescription";

// import { Container } from './styles';

function HistoryDetails({ isOpen, handleClose, history }) {
  const themes = useTheme();
  const pictures = useMemo(
    () => history.attachments || [],
    [history.attachments]
  );
  const [selectedPicture, setSelectedPicture] = useState("");

  const { isOpen: isPictureViewOpen, close, open } = useDisclosure(false);

  const handleClickPicture = useCallback(
    (picture) => {
      
      setSelectedPicture(picture);
      open();
    },
    [open]
  );

  return (
    <HalfwayDialog open={isOpen} handleClose={handleClose}>
      <Box
        width="100%"
        py={"8px"}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Heading color={themes.palette.primary.dark}>
          Detalhes do histórico
        </Heading>
      </Box>
      <Box padding="8px">
        <ItemDescription {...history} />
        <Pictures>
          {pictures.map((src, index) => (
            <CardImage
              src={src}
              key={`image-${index}`}
              onClick={() => handleClickPicture(src)}
              isDeletable={false}
              fileName={`image-${index}`}
            />
          ))}
        </Pictures>
        <PictureModal
          open={isPictureViewOpen}
          onClose={close}
          src={selectedPicture}
          alt={"image"}
        />
      </Box>
    </HalfwayDialog>
  );
}

export default HistoryDetails;
