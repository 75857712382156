import { Box, Button, makeStyles } from "@material-ui/core";
import { ArrowBack, ArrowForward, DeleteOutline } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((themes) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: themes.spacing(6),

  },
  spacing: {
    "& > button": {
      marginBottom: themes.spacing(2),
    }
  },

  error: {
    color: themes.palette.error.main,
  }
}));

export function PictureModePicker({
  onClickBack,
  onClickTakePicture,
  onClickChoosePicture,
  onClickExcludePicture,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="flex-end" className={classes.spacing}>
        <Button
          size="medium"
          onClick={onClickTakePicture}
          fullWidth
          color="primary"
          variant="contained"
        >
          Tirar uma foto
        </Button>
        <Button
          size="medium"
          onClick={onClickChoosePicture}
          fullWidth
          color="secondary"
          variant="contained"
        >
          Buscar uma foto
        </Button>
        <Button
        className={classes.error}
          onClick={onClickExcludePicture}
          startIcon={<DeleteOutline />}
          fullWidth
        >
          Excluir fotos
        </Button>
      </Box>
      <Box marginTop="auto" paddingBottom="24px">
        <Button onClick={onClickBack} startIcon={<ArrowBack />} fullWidth >
          Cancelar
        </Button>
      </Box>
    </Box>
  );
}

export default PictureModePicker;
