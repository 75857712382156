import { useCallback, useEffect, useState } from "react";

export const FILTER_LOCAL_STORAGE_KEY = "viana-filters";

const buildKey = (id) => `${FILTER_LOCAL_STORAGE_KEY}-${id}`;

const getCachedFilter = (id) => {
  try {
    const stringifiedFilters = localStorage.getItem(buildKey(id));
    const filter = JSON.parse(stringifiedFilters);
    return filter || {};
  } catch (error) {
    return {};
  }
}
export function useHydrateFilters(pageId) {
  const [filters, _setFilters] = useState(() => getCachedFilter(pageId));

  useEffect(() => {
    const filters = getCachedFilter(pageId);
    if (!filters) {
      _setFilters({});
      return;
    }

    _setFilters(filters);
  }, [pageId]);

  const setFilter = useCallback(
    (filters) => {
      localStorage.setItem(buildKey(pageId), JSON.stringify(filters));
      _setFilters(filters);
    },
    [pageId]
  );

  return [filters, setFilter];
}
