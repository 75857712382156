import moment from "moment";
import { useMemo } from "react";
import { isSameDay } from "../../utils/list/dateComparator";
import { isSameNeighborhood } from "../../utils/list/neighborhoodComparator";

const enTabs = {
  DATA: 0,
  BAIRRO: 1,
  STATUS: 2,
};

const aggregators = {
  [enTabs.DATA]: {
    shouldRenderHeader: isSameDay,
    getText: (demand) => moment(demand.createdAt).format("DD/MM/YYYY"),
  },

  [enTabs.BAIRRO]: {
    shouldRenderHeader: isSameNeighborhood,
    getText: (demand) => demand.neighborhood,
  },

  [enTabs.STATUS]: {
    shouldRenderHeader: (a, b) => a.status.private === b.status.private,
    getText: (demand) => demand.status.private,
  },
};

export function useListHelper(orderBy) {
  const helper = useMemo(() => aggregators[orderBy], [orderBy]);
  return helper;
}
