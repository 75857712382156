import { enOrderTypeApiMapper } from "../../enums";
import { enHttpVerbs } from "../../enums/http-verbs";
import { enServiceRequestPrivateStatus } from "../../enums/service";
import AbstractApiService from "../abstractApiService";

export class DemandApi extends AbstractApiService {
  static async findOne(demandId) {
    const { data } = await this.fetch(`/service-request/${demandId}`);
    return data.data.result;
  }

  static async list(sort, pagination, filters) {
    const filter = JSON.stringify(filters);

    const queryUrl = new URLSearchParams({
      filter,
      page: pagination.page,
      countPerPage: pagination.limit,
      orderDirection: sort.direction,
      orderBy: enOrderTypeApiMapper[sort.by],
    });

    const { data } = await this.fetch(
      `/service-request/?${queryUrl.toString()}`
    );

    return {
      demands: data.data.result.list,
      countQuery: data.data.result.countQuery,
      countTotal: data.data.result.countTotal,
    };
  }

  static getStatus() {
    // ?? That should come from backend?
    const serviceAvailableStatus = Object.values(enServiceRequestPrivateStatus);
    return serviceAvailableStatus;
  }

  static async addHistory(demandId, history) {
    const defaultHeaders = DemandApi.defaultHeaders;
    delete defaultHeaders["Content-Type"];

    const { data } = await this.fetch(
      `/service-request/${demandId}`,
      {
        body: history,
        method: enHttpVerbs.POST,
        headers: defaultHeaders,
      }
    );

    return data.data.result;
  }
}
