import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ServiceApiService } from "../../services/api/service-api";
import { DemandApi } from "../../services/api/demands";

export const FilterContext = createContext({
  availableServices: [],
  availableStatus: [],
  isReady: false,
});

export function FilterContextProvider({ children }) {
  const [availableServices, setAvailableServices] = useState([]);
  const [availableStatus, setAvailableStatus] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const getContextData = useCallback(async () => {
    const [services, statusCollection] = await Promise.all([
      ServiceApiService.list(),
      DemandApi.getStatus(),
    ]);

    setAvailableServices(services);
    setAvailableStatus(statusCollection);
    setIsReady(true);
  }, []);

  useEffect(() => {
    getContextData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(() => {
    return {
      isReady,
      availableStatus,
      availableServices,
    };
  }, [isReady, availableStatus, availableServices]);

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
}

export function useFilterContext() {
  const context = useContext(FilterContext);
  return context;
}
