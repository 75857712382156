import { enErrors } from "../../enums/error-events";
import { enNotificationTypes } from "../../enums/notification-types";
import { AppEventsManager } from "../../services/emitter";

export default class ErrorAbstract extends Error {
  static code = "0000";
  static message = "";

  static throwError(append = "", log = true) {
    const ErrorClass = this;
    const error = new ErrorClass(`${ErrorClass.message}\n${append}`);
    log && console.log(error);
    throw error;
  }

  static registerUnhandledExceptionListener() {
    const handlerException = (errorEvent) => {
      const reason = errorEvent.reason || {};
      AppEventsManager.emit(enErrors.GLOBAL, reason);
    };

    window.addEventListener("unhandledrejection", handlerException);

    return () => {
      AppEventsManager.off(enErrors.GLOBAL);
      window.removeEventListener("unhandledrejection", handlerException);
    };
  }
}
