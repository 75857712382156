import { enHttpVerbs } from "../../enums/http-verbs";
import AbstractApiService from "../abstractApiService";

export default class AuthenticateService extends AbstractApiService {
  static async login(userCredentials) {
    const response = await this.fetch(`/auth/login`, {
      method: "POST",
      body: JSON.stringify(userCredentials),
    });

    return response.data;
  }

  static async check(token) {
    const response = await this.fetch(`/auth/check`, {
      method: "POST",
      body: JSON.stringify({ token }),
    });

    const apiResponse = await response.json();
    response.data = apiResponse;
    return response;
  }

  static async getEmailByDocument(document) {
    const { data } = await this.fetch(`/auth/email?document=${document}`);
    return data.data.email;
  }

  static async sendRecoveryPasswordRequest({ email, document }) {
    const request = await this.fetch(`/auth/recovery-password`, {
      method: enHttpVerbs.POST,
      body: JSON.stringify({ email, document }),
    });

    return request.data;
  }

  static async updateNotificationToken({ token }) {
    await this.fetch(`/user/notification-token`, {
      method: enHttpVerbs.POST,
      body: JSON.stringify({ token }),
    });

    return true;
  }
}
