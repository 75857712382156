import {
  Box,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React, { useMemo } from "react";
import { enHistoryTypeLabel } from "../../enums/service";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  status: {
    paddingTop: theme.spacing(1),
    fontWeight: 800,
    fontSize: ".6rem",
    textTransform: "uppercase",
    letterSpacing: ".53px",
  },
  iconButton: {
    marginLeft: "auto",
  },
}));

const getLabelType = (type) => {
  const val = enHistoryTypeLabel[type];
  return val;
};

function ItemDescription({
  type,
  isPublic,
  message,
  onClickInfo,
  showInfoBtn,
}) {
  const classes = useStyles();
  const typeLabel = useMemo(() => getLabelType(type), [type]);
  const { status, statusColor } = useMemo(
    () =>
      isPublic
        ? {
            status: "Público",
            statusColor: "secondary",
          }
        : {
            status: "Privado",
            statusColor: "error",
          },
    [isPublic]
  );

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" component="h6">
          {typeLabel}
        </Typography>
        <Typography className={classes.status} color={statusColor}>
          {status}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">{message}</Typography>
      </Box>
      {showInfoBtn && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={onClickInfo}
            color="primary"
            className={classes.iconButton}
          >
            <InfoOutlined />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default ItemDescription;
