import { EventEmitter } from "events";
import firebase from "firebase/app";
import "firebase/messaging"

export class Fcm extends EventEmitter {
  static MESSAGE = "on-message";
  static LOCAL_STORAGE_KEY = "fcm-message";

  constructor({ vapidKey, ...firebaseConfig }, options) {
    super();

    this._onMessage = this._onMessage.bind(this);
    this.maxTokenRetrieveAttempts = options?.maxTokenRetrieveAttempts || 3;

    this.config = firebaseConfig;
    this.vapidKey = vapidKey;
    firebase.initializeApp(this.config);

    this.messaging = firebase.messaging()

    this.messaging.onMessage(this._onMessage);
  }

  _onMessage(payload) {
    console.log("payload: ", payload);
    
    this.emit(Fcm.MESSAGE, payload);
  }

  async getToken(attempt = 0) {
    if (attempt > this.maxTokenRetrieveAttempts) {
      throw new Error("Não foi possivel gerar token de push notification");
    }

    const token = await this.messaging.getToken({ vapidKey: this.vapidKey });
    const hasToken = !!token;

    if (!hasToken) {
      await this.requestNotificationAuth();
      return this.getToken(attempt + 1);
    }

    console.log(token);
    return token;
  }

  async deleteToken() {
    try {
      await this.messaging.deleteToken()
      return true;
    } catch (error) {
      return false;
    }
  }

  subscribe(listener) {
    this.on(Fcm.MESSAGE, listener);
    return () => this.off(EventEmitter.MESSAGE, listener);
  }

  async refreshToken() {
    const oldToken = await this.getToken();
    const hasTokenDeleted = await this.deleteToken(oldToken);

    if (!hasTokenDeleted) throw new Error("Failed to refresh push token");
    return this.getToken();
  }
}
