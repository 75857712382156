import {
  Box,
  Chip,
  Divider,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import AppBarComponent from "../appbar";
import { FilterButton, FilterModal } from "./Filters";
import { OrderTabs } from "./Ordertabs";
import { useRequestListing } from "./useRequestListing";
import { useListHelper } from "./useListHelper";
import { DemandInfiniteList as DemandList } from "./DemandInfiniteList";
import clsx from "clsx";
import { enOrder, enOrderType } from "../../enums";
import { FilterResumedList } from "../RequestFilters/FilterResumedList";
import { FilterContextProvider } from "../RequestFilters/FilterContext";
import { useDisclosure } from "../../hooks/useDisclosure";
import { getOnlyValidProps } from "../RequestFilters/filterSchemaValidator";
import { useHydrateFilters } from "../RequestFilters/useHydrateFields";
import { enServiceRequestPrivateStatus } from "../../enums/service";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    zIndex: theme.zIndex.appBar,
  },

  offSetClosedFilters: {
    margin: "115px",
    transition: `${theme.transitions.create(["margin", "transform"], {
      duration: theme.transitions.duration.standard,
    })}`,
  },

  offSetOpenFilters: {
    margin: "145px",
  },
  textCenter: {
    textAlign: "center",
  },
}));

const infiniteScrollStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

const filterToActionsMapper = {
  intervalStart: "Inicio de intervalo",
  intervalEnd: "Fim de intervalo",
  services: "Serviço",
  status: "Status",
  requesterName: "Nome",
  address: "Endereço",
  publicPerson: "Pessoa pública",
  fisicPerson: "Pessoa fisica",
};

const filterActiveServiceRequests = {
  status: [
    enServiceRequestPrivateStatus.ABERTA,
    enServiceRequestPrivateStatus.EM_ATRASO,
    enServiceRequestPrivateStatus.PENDENTE,
  ],
};

export function RequestList() {
  const [tabName, setSelectedOrderType] = useState(0);
  const listHelper = useListHelper(tabName, []);
  const [orderDirection, setOrderDirection] = useState(enOrder.ASC);
  const oldOrderType = useRef(tabName);
  const [filters, setFilters] = useHydrateFilters(tabName);

  const {
    isOpen: isFilterModalOpen,
    toggle: toggleFilterModal,
    close: closeFilterModal,
  } = useDisclosure(false);

  const classes = useStyles();
  const hasFilters = useMemo(
    () => Object.keys(filters).some((key) => !!filters[key]),
    [filters]
  );

  const orderListMetaInfo = useMemo(
    () => ({
      by: tabName,
      direction: orderDirection,
    }),
    [tabName, orderDirection]
  );

  const filterWithDefault = useMemo(() => {
    const shouldAddDefaultFilters = [
      enOrderType.BAIRRO,
      enOrderType.DATA,
    ].includes(tabName);

    if (shouldAddDefaultFilters)
      return {
        ...filterActiveServiceRequests,
        ...filters,
      };
    return { ...filters };
  }, [filters, tabName]);

  const { items, hasMore, getNextPage, isFetching } = useRequestListing(
    orderListMetaInfo,
    filterWithDefault
  );

  useEffect(() => {
    if (items.length === 0 && hasMore) {
      window.scrollY = 0;
      getNextPage();
    }
  }, [items, hasMore, getNextPage]);

  const handleChangeTab = useCallback((newOrderType) => {
    if (oldOrderType.current === newOrderType) {
      setOrderDirection((oldDirection) => oldDirection * -1);
    } else {
      setOrderDirection(enOrder.ASC);
      oldOrderType.current = newOrderType;
    }

    setSelectedOrderType(newOrderType);
  }, []);

  const handleFilterChange = useCallback(
    async (values) => {
      const validFilters = await getOnlyValidProps(values);
      setFilters(validFilters);
      closeFilterModal();
    },
    [closeFilterModal, setFilters]
  );

  const clearFilter = useCallback(
    (filterProp) => {
      return () => {
        setFilters((oldFilters) => {
          const oldFiltersClone = { ...oldFilters };
          oldFiltersClone[filterProp] = undefined;
          delete oldFiltersClone[filterProp];
          return oldFiltersClone;
        });
      };
    },
    [setFilters]
  );

  const resetAllFilters = useCallback(() => {
    setFilters({});
    closeFilterModal();
  }, [closeFilterModal, setFilters]);

  return (
    <FilterContextProvider>
      <Box width="100%" height="100%" overflow="scroll">
        <Box className={classes.headerWrapper}>
          <AppBarComponent title="TODAS AS DEMANDAS" />
          <Divider />
          <OrderTabs
            value={tabName}
            onChange={handleChangeTab}
            order={orderDirection}
          >
            <FilterResumedList open={hasFilters}>
              {Object.keys(filters).map((key) => (
                <Chip
                  label={filterToActionsMapper[key]}
                  onDelete={clearFilter(key)}
                  color="secondary"
                  size="small"
                />
              ))}
            </FilterResumedList>
          </OrderTabs>
          <FilterButton onClick={toggleFilterModal} />
        </Box>

        <Box
          className={clsx(classes.offSetClosedFilters, {
            [classes.offSetOpenFilters]: hasFilters,
          })}
        />

        <InfiniteScroll
          dataLength={items.length}
          next={getNextPage}
          hasMore={hasMore && !isFetching}
          loader={
            <Box padding="8px" marginBottom="8px" width="100%">
              <LinearProgress color="secondary" />
            </Box>
          }
          style={infiniteScrollStyles}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Não há mais demandas</b>
            </p>
          }
        >
          <DemandList
            comparator={listHelper.shouldRenderHeader}
            getText={listHelper.getText}
            demands={items}
          />
        </InfiniteScroll>
      </Box>
      <FilterModal
        open={isFilterModalOpen}
        filters={filters}
        handleFilterChange={handleFilterChange}
        onClose={closeFilterModal}
        onReset={resetAllFilters}
      />
    </FilterContextProvider>
  );
}

export default RequestList;
