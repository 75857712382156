import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export default function RouteComponent(props) {
  const { isPrivate, component: Component, ...rest } = props;
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (isAuthenticated && !isPrivate) {
    return <Redirect to="/home" />;
  }

  //   if (isAuthenticated && isPrivate) {
  //     if (roles) {
  //       const hasPermission = roles.some((r) => user.roles?.includes(r));
  //       if (!hasPermission) {
  //         return <Redirect to="/" />;
  //       }
  //     }
  //   }

  return (
    <Route {...rest}>
      <Component {...rest} />
    </Route>
  );
}
