import {
  makeStyles,
} from "@material-ui/core";
import { Timeline } from "@material-ui/lab";
import React, { useCallback, useState } from "react";
import { useDisclosure } from "../../hooks/useDisclosure";
import { HistoryItem } from "./TimelineItem";
import HistoryDetails from "./HistoryDetails";
import If from "../If";

const useStyles = makeStyles((theme) => ({
  historyPreviewContainer: {
    border: `.85px solid ${theme.palette.text.secondary}`,
    height: "200px",
    overflow: "scroll",
    margin: theme.spacing(2, 0),
  },
  headerModal: {
    color: theme.palette.primary.main,
  },
  modalBody: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
  },

  timeline: {
    paddingBottom: "40px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  btn: {},
  collapse: {
    position: "relative",
  },
}));

export function History({ demand, highlightCount = 3 }) {
  const classes = useStyles();
  const [selectedHistory, setSelectedHistory] = useState();

  const {
    isOpen: isModalOpen,
    open: handleOpenModal,
    close: handleCloseModal,
  } = useDisclosure(false);

  const handleClickInfo = useCallback(
    (historyItem) => {
      setSelectedHistory(historyItem);
      handleOpenModal();
    },
    [handleOpenModal]
  );

  const getFooterInfo = useCallback(
    (history) =>
      history.attachments.length
        ? `Adicionou ${history.attachments.length} fotos`
        : "Não adicionou fotos",
    []
  );

  return (
    <>
      <Timeline className={classes.timeline}>
        {(demand.history || []).map((history, index) => {
          return (
            <HistoryItem
              onClickInfo={() => handleClickInfo(history)}
              type={history.type}
              status={history.status}
              message={history.message}
              isPublic={history.public}
              key={`item-line-${index}`}
              requester={history.user?.name}
              footerInfo={getFooterInfo(history)}
            />
          );
        })}
      </Timeline>
      <If condition={!!selectedHistory}>
        <HistoryDetails
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          history={selectedHistory}
        />
      </If>
    </>
  );
}
