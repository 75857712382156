import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { useAuth } from "../../context/AuthContext";

export const FcmContext = createContext({});

export function FcmContextProvider({ messagingManager, children }) {
  const { user } = useAuth();
  const messaging = useMemo(
    () => messagingManager.getMessaging(),
    [messagingManager]
  );

  const onMessage = useCallback(
    (listener) => {
      return messaging.subscribe(listener);
    },
    [messaging]
  );


  useEffect(() => {
    const execute = async () => {
      try {
        if(!user) return;
        
        const userAllowNotifications = await messagingManager.requestNotificationAuth();
        if(!userAllowNotifications) return;

        let token = await messaging.getToken();
        const isValidExpirationDate = messagingManager.isTokenValid();

        if (isValidExpirationDate) {
          return;
        }

        await messaging.deleteToken(token);
        token = await messaging.getToken();

        await messagingManager.sendTokenToServer(token);
      } catch (error) {
        console.error(error);
      }
    };
    execute();
  }, [messagingManager, messaging, user]);

  return (
    <FcmContext.Provider value={{ onMessage }}>
      {children}
    </FcmContext.Provider>
  );
}

export function useFcm() {
  const context = useContext(FcmContext);
  return context;
}
