import React, { useCallback, useEffect, useState } from "react";
import { AppEventsManager } from "../services/emitter";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { AsideDrawerMenu } from "./AsideDrawerMenu";
import { useAuth } from "../context/AuthContext";
import { useNotificationContext } from "../context/NotificationContext";
import { enNotificationTypes } from "../enums/notification-types";
import ListIcon from "@material-ui/icons/List";
import { Link } from "react-router-dom";

const SideMenu = () => {
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();
  const { addDialog } = useNotificationContext();

  const onLogout = useCallback(async () => {
    const hasUserAllowLogout = await addDialog({
      type: enNotificationTypes.INFO,
      message: "Tem certeza que deseja sair?",
      title: "Logout",
      cancelText: "Não",
      okText: "Sim",
    });

    if (hasUserAllowLogout) return logout();
  }, [addDialog, logout]);

  const onToggle = useCallback(() => setOpen((oldState) => !oldState), []);
  const onClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    AppEventsManager.on("menu-toggle", onToggle);
    return () => AppEventsManager.off("menu-toggle", onToggle);
  }, [onToggle]);

  return (
    <AsideDrawerMenu onClose={onClose} onOpen={onToggle} open={open}>
      <ListItem component={Link} button onClick={onClose} to="/home">
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="Lista" />
      </ListItem>
      <ListItem component="button" button onClick={onLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </AsideDrawerMenu>
  );
};

export default SideMenu;
