import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DemandApi } from "../../services/api/demands";
import { demandFilterApiDtoParser } from "../../ApiDTO/demand-filters";
import debounce from "debounce";

const isValidRequest = (request) => !request.isSkeleton;
const PAGE_SIZE = 15;
const DEFAULT_SKELETON = { isSkeleton: true };

const appendSkeletons = (oldRequests) =>
  oldRequests.concat(
    Array.from({ length: PAGE_SIZE }).map(() => DEFAULT_SKELETON)
  );

export function useRequestListing(order, filter) {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const page = useRef(0);

  useEffect(() => {
    setItems([]);
    setHasMore(true);
    page.current = 0;
  }, [order, filter]);

  const fetchMoreData = useCallback(
    async (pagination, filter) => {
      setIsFetching(true);
      const filters = demandFilterApiDtoParser(filter);
      const demandPage = await DemandApi.list(order, pagination, filters);
      demandPage.pagination = pagination;

      setIsFetching(false);
      return demandPage;
    },
    [order]
  );

  const _getNextPage = useCallback(
    async (page) => {
      setItems(appendSkeletons);
      const { demands: newRequests } = await fetchMoreData(
        { page, limit: PAGE_SIZE },
        filter
      );

      if (newRequests.length < PAGE_SIZE) {
        setHasMore(false);
      }

      setItems((oldRequest) =>
        oldRequest.filter(isValidRequest).concat(newRequests)
      );
    },
    [fetchMoreData, filter]
  );

  const getNextPage = useMemo(
    () => debounce(() => _getNextPage(page.current++), 200),
    [_getNextPage]
  );

  return { getNextPage, items, hasMore, isFetching };
}
